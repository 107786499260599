import { t } from '../languages';
import service from "./service";

const Roles = {
    // 新增角色
    addRole: (data) => {
        return service.post({
            url: `/api/v1/add/role?roleName=${data.roleName}&type=${data.type}`
        })
    },
    // 获取角色列表
    getRoleList: () => {
        return service.get({
            url: `/api/v1/show/roles/list`
        })
    },
    // 修改角色信息
    modifyRole: (data) => {
        return service.put({
            url: `/api/v1/settings/role`,
            data
        })
    },
    //根据id获取角色信息
    getRoleIdList: (roleId) => {
        return service.get({
            url: `/api/v1/show/role/${roleId}`
        })
    },
    //根据id删除角色信息
    delRoleIdList: (roleId) => {
        return service.delete({
            url: `/api/v1/delete/role/${roleId}`
        })
    },
    // 判断是否是超级管理员
    isSuperAdmin: () => {
        return service.get({
            url: '/api/v1/role/isSuperAdmin'
        })
    },
    //修改权限
    modifyPermissions: (data) => {
        return service.post({
            url: `/api/v1/authField/settings`,
            data
        })
    },
    //获取权限字段
    getPermissions: (id) => {
        return service.get({
            url: `/api/v1/authField/show/${id}`
        })
    }

}
export default Roles