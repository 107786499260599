<template>
  <div class="container">
    <nav class="nav">
      <NavMenu :navlist="navlist"></NavMenu>
    </nav>
    <!--角色列表  -->
    <div class="members color">
      <div class="header flex-sb">
        <div class="headerN">{{ t('666') }}</div>
        <div class="add" @click="addRole" v-checkAuth="'user-role-add'">
          <img src="../../asset/img/add.png" alt="" />
        </div>
      </div>
      <!-- 列表 -->
      <div class="List">
        <div class="libox" v-if="roleLists.length != 0">
          <div
            class="li flex-fs"
            :class="index == lindex ? 'active' : ''"
            v-for="(item, index) in roleLists"
            :key="index"
            @click="choice(index)"
          >
            <span class="licon"><i class="iconfont icon-guanliyuan"></i></span>
            <div class="name overflow_text" :title="item.roleName">{{ item.roleName }}</div>
            <el-popover
              placement="bottom"
              :show-arrow="false"
              trigger="hover"
              popper-class="nopadding_popover"
            >
              <template #reference>
                <span class="ricon">
                  <el-icon><MoreFilled /></el-icon>
                </span>
              </template>
              <!-- popover内容 -->
              <ul class="popover_ul">
                <li @click="modify(item)" v-checkAuth="'user-role-update'">
                  {{ t('652') }}
                </li>
                <li
                  @click="del(index, item.id)"
                  v-checkAuth="'user-role-delete'"
                >
                  {{ t('210') }}
                </li>
              </ul>
            </el-popover>
          </div>
        </div>
        <div
          v-else
          style="color: #666666; text-align: center; font-size: 0.875rem"
        >
          {{ t('223') }}
        </div>
      </div>
    </div>
    <!-- 添加弹窗 -->
    <el-dialog
      v-model="dialogVisible"
      :title="addtitle"
      width="380px"
      top="30vh"
    >
      <el-form
        :model="addFrom"
        :rules="rouleRules"
        ref="roleRef"
        class="elfrom"
        label-width="80px"
        label-position="top"
      >
        <el-form-item :label="t('667')" prop="roleName">
          <el-input
            class="rolePut"
            v-model.trim="addFrom.roleName"
            onkeyup="this.value=this.value.replace(/\ +/g, '')"
            :placeholder="t('668')"
            maxlength="20"
          ></el-input>
        </el-form-item>
        <div class="roleType">
          <el-form-item prop="type">
            <div class="roleGarde">
              <span>{{ t('669') }}</span>
              <el-tooltip
                  placement="top-start"
                  popper-class="atooltip"
                  effect="light"
                >
                  <template #content>
                    <div class="tooltiptext">
                      <div class="feildG">{{ t('669') }}</div>
                      <div class="feildF">
                        {{ t('671') }}
                      </div>
                    </div>
                  </template>
                  <img class="hover_opacity" src="../../asset/img/feild.png" alt="" />
                </el-tooltip>
            </div>
            <div class="changeG flex-sb">
              <div
                class="gbtn hover_opacity"
                :class="
                  index == gradeIndex
                    ? 'active'
                    : addFrom.type == (item == t('670') ? 'ADMIN' : 'USER')
                    ? 'active'
                    : ''
                "
                v-for="(item, index) in changeGarde"
                :key="index"
                @click="changeGrade(index)"
              >
                {{ item }}
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <!-- 底部 -->
      <div class="footBtn flex-fe">
        <div class="lBtn hover_opacity" @click="cancel">{{ t('217') }}</div>
        <div class="rBtn hover_opacity" @click="subMit">{{ t('218') }}</div>
      </div>
    </el-dialog>
    <div class="conRight">
      <!-- 角色权限 -->
      <div class="role">
        <div class="rolehd">
          <div class="roletop">{{ t('660') }}</div>
          <div class="rolet flex-a">
            <div>
              {{ t('667') }}: <span>{{ roleInformation.roleName }}</span>
            </div>
            <div class="namer">
              {{ t('594') }}:<span>{{
                roleInformation.type == "USER"
                  ? t('672')
                  : roleInformation.type == "ADMIN"
                  ? t('670')
                  : roleInformation.type == "SUPPER_ADMIN"
                  ? t('673')
                  : ""
              }}</span>
            </div>
          </div>
          <div
            class="role_edit"
            v-if="editShow"
            @click="roleEdit"
            v-checkAuth="'user-role-update'"
          >
            {{ t('674') }}
          </div>
        </div>
        <div class="rolebox">
          <div class="role_content">
            <el-table
              ref="multipleTableRef"
              :data="renderRoleMenu"
              row-key="id"
              :default-expand-all="true"
              style="height: 90%"
              width="100%"
              :indent="16"
              :tree-props="{
                children: 'children',
                hasChildren: 'hasChildren',
              }"
              @row-click="clickTable"
            >
              <!--      
            @selection-change="handleSelectionChange"       -->
              <el-table-column align="left" :label="t('675')" min-width="55%">
                <template type="expand" #default="scope" class="flex">
                  <el-row>
                    <el-col
                      :span="4"
                    >
                      {{ scope.row.path }}
                    </el-col>
                    <el-col :span="26">
                      <!-- <div :class="(scope.row.id=='311'||scope.row.id=='312'||scope.row.id=='331'||scope.row.id=='332')?'checkedLeft':''"> -->
                        <!--  -->
                        <el-checkbox
                          v-for="item in scope.row?.itemChildren"
                          :key="item.id"
                          v-model="item.enable"
                          :label="item.label"
                          @change="handleCheckedItemChange"
                          >{{ item.path }}</el-checkbox
                        >
                      <!-- </div> -->
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <!-- <el-table-column
                type="selection"
                align="center"
                :label="t('676')"
              ></el-table-column> -->
              <el-table-column align="center" width="65">
                <!-- 头部 -->
                <template #header :solt="scope">
                  <el-checkbox
                    v-model="selectAll"
                    :indeterminate="isIndeterminate"
                    @change="handleCheckAllChange"
                    size="large"
                  ></el-checkbox>
                </template>
                <!-- 表体  -->
                <template #default="scope">
                  <el-checkbox
                    v-if="scope.row?.itemChildren"
                    v-model="scope.row.enable"
                    :indeterminate="scope.row.indeterminate"
                    @change="handleCheckChange(scope.row, $event)"
                    size="large"
                  />
                </template>
              </el-table-column>
            </el-table>
            <div class="role_model" v-if="editShow" @click="editDialog"></div>
          </div>
        </div>
        <div class="flex-fe role_btn" v-if="!editShow">
          <div class="lBtn cuor" @click="roleEditClose">{{ t('217') }}</div>
          <div class="rBtn cuor" @click="roleEditSubmit">{{ t('225') }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- 角色权限删除 -->
  <delDialog
    v-if="delDialogShow"
    :isVisible="delDialogShow"
    :dialogTitle="'提示'"
    @on-cancel="delCancel"
    @on-add="delSubmit"
    class="del"
  >
    <!--  -->
    <div>{{ t('677') }}</div>
  </delDialog>
</template>

<script>
import { t } from '../../languages';
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import Roles from "../../network/personal";
import NavMenu from "@/components/common/nav-menu.vue";
import delDialog from "../../components/package/ele-dialog.vue";
import { ElMessage } from "element-plus";
import { store } from "@/store";

export default defineComponent({
  components: {
    delDialog,
    NavMenu,
  },
  setup() {
    //用户信息
    // console.log(store.state.user.userInfo.type)
    // const useType = computed(() => {

    //   return store.state.user.userInfo.value.type;
    // });
    const roleRef = ref(null);
    const multipleTableRef = ref(null);
    const state = reactive({
      editShow: true,
      dialogVisible: false, //角色权限添加弹窗
      showVisType: "add",
      navid: 1,
      // 列表
      lindex: 0,
      useType: store.state.user.userInfo.type,
      // 选择等级
      gradeIndex: -1,
      // 角色切换
      navlist: [
        { id: 1, name: t("168"), path: "/personManageIndex" },
        { id: 2, name: t("660"), path: "/roleOrder" },
        { id: 3, name: t("661"), path: "/teamManage" },
      ],
      // 添加修改切换title
      addtitle: "",
      // 删除id
      delId: 0,
      // 移除索引
      delIndex: 0,
      // 角色列表
      roleLists: [
        // 处理如果是超级管理员移除剩下保留
      ],
      changeGarde: [t("670"), t("672")],
      editType: "",
      // 添加表单
      addFrom: {
        roleName: "",
        type: "",
      },
      rouleRules: {
        roleName: [
          { required: true, message: t("668"), trigger: "change" },
        ],
        type: [
          { required: true, message: t("678"), trigger: "change" },
        ],
      },
      // 传送到role组件
      roleInformation: {
        roleName: "",
        type: "",
      },
      // 修改权限
      modifyFrom: {
        roleId: "",
        rolePermissions: "",
      },
      delDialogShow: false, //角色权限删除弹窗
      renderRoleMenu: [],
      roleMenu: [
        // {
        //   path: t("679"),
        //   name: t("680"),
        //   id: 1,
        //   enable: false,
        //   indeterminate: false,
        //   children: [
        //     {
        //       path: t("681"),
        //       name: t("680"),
        //       id: 11,
        //       enable: false,
        //       indeterminate: false,
        //       children: [
        //         {
        //           path: t("682"),
        //           name: t("683"),
        //           id: 111,
        //           enable: false,
        //         },
        //       ],
        //     },
        //     {
        //       path: t("519"),
        //       name: t("680"),
        //       id: 12,
        //       enable: false,
        //       indeterminate: false,
        //       children: [
        //         {
        //           path: t("384"),
        //           name: t("683"),
        //           id: 121,
        //           look: false,
        //           enable: false,
        //         },
        //         {
        //           path: t("647"),
        //           name: t("683"),
        //           id: 122,
        //           look: false,
        //           enable: false,
        //         },
        //         {
        //           path: t("684"),
        //           name: t("683"),
        //           id: 123,
        //           look: false,
        //           enable: false,
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          path: t("154"),
          name: t("680"),
          id: 2,
          enable: false,
          indeterminate: false,
          children: [
            {
              path: t("685"),
              name: t("680"),
              id: 21,
              enable: false,
              indeterminate: false,
              children: [
                // {
                //   path: t("354"),
                //   name: t("683"),
                //   id: 211,
                //   look: false,
                //   enable: false,
                // },
                {
                  path: t("686"),
                  name: t("683"),
                  id: 212,
                  look: false,
                  enable: false,
                   indeterminate: false,
                  itemChildren: [
                    {
                      path: t("687"),
                      name: t("683"),
                      id: 2121,
                      value: "data-routePlanning-query",
                      enable: false
                    },
                  ]
                },
                {
                  path: t("131"),
                  name: t("680"),
                  id: 213,
                  look: false,
                  enable: false,
                  indeterminate: false,
                  itemChildren: [
                    {
                      path: t("459"),
                      name: t("683"),
                      id: 2131,
                      value: "airport-test",
                      enable: false
                    },
                    // {
                    //   path: t("688"),
                    //   name: t("683"),
                    //   id: 2132,
                    //   look: false,
                    //   enable: false,
                    // },
                    // {
                    //   path: t("468"),
                    //   name: t("683"),
                    //   id: 2133,
                    //   look: false,
                    //   enable: false,
                    // },
                    // {
                    //   path: t("469"),
                    //   name: t("683"),
                    //   id: 2134,
                    //   look: false,
                    //   enable: false,
                    // },
                    // {
                    //   path: t("470"),
                    //   name: t("683"),
                    //   id: 2135,
                    //   look: false,
                    //   enable: false,
                    // },
                  ],
                },
              ],
            },
            // {
            //   path: t("689"),
            //   name: t("683"),
            //   id: 22,
            //   enable: false,
            // },
            // {
            //   path: t("690"),
            //   name: t("680"),
            //   id: 23,
            //   enable: false,
            //   indeterminate: false,
            //   children: [
            //     {
            //       path: t("273"),
            //       name: t("683"),
            //       id: 231,
            //       enable: false,
            //     },
            //     {
            //       path: t("511"),
            //       name: t("683"),
            //       id: 232,
            //       look: false,
            //       enable: false,
            //     },
            //     {
            //       path: t("285"),
            //       name: t("683"),
            //       id: 233,
            //       enable: false,
            //     },
            //     {
            //       path: t("691"),
            //       name: t("683"),
            //       id: 234,
            //       enable: false,
            //     },
            //     {
            //       path: t("692"),
            //       name: t("683"),
            //       id: 235,
            //       enable: false,
            //     },
            //   ],
            // },
          ],
        },
        {
          path: t("155"),
          name: t("680"),
          id: 3,
          look: false,
          enable: false,
          indeterminate: false,
          itemVal: [],
          children: [
            {
              path: t("156"),
              name: t("680"),
              id: 31,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              children: [
                {
                  path: t("693"),
                  name: t("680"),
                  id: 311,
                  look: false,
                  enable: false,
                  indeterminate: false,
                  itemVal: [],
                  itemChildren: [
                    {
                      path: t("694"),
                      name: t("683"),
                      id: 3111,
                      value: "data-asset-query",
                      enable: false,
                    },
                    {
                      path: t("648"),
                      name: t("683"),
                      id: 3112,
                      value: "data-asset-import",
                      enable: false,
                    },
                    {
                      path: t("647"),
                      name: t("683"),
                      id: 3113,
                      value: "data-asset-export",
                      enable: false,
                    },
                    {
                      path: t("652"),
                      name: t("683"),
                      id: 3114,
                      value: "data-asset-update",
                      enable: false,
                    },
                    {
                      path: t("210"),
                      name: t("683"),
                      id: 3115,
                      value: "data-asset-delete",
                      enable: false,
                    },
                    {
                      path: t("695"),
                      name: t("683"),
                      id: 3116,
                      value: "data-asset-paste",
                      enable: false,
                    },
                    {
                      path: t("696"),
                      name: t("683"),
                      id: 3117,
                      value: "data-folder-update",
                      enable: false,
                    },
                    {
                      path: t("697"),
                      name: t("683"),
                      id: 3118,
                      value: "data-folder-query",
                      enable: false,
                    },
                    {
                      path: t("698"),
                      name: t("683"),
                      id: 3119,
                      value: "data-folder-delete",
                      enable: false,
                    },
                  ],
                },
                {
                  path: t("699"),
                  name: t("680"),
                  id: 312,
                  look: false,
                  enable: false,
                  indeterminate: false,
                  itemVal: [],
                  itemChildren: [
                    {
                      path: t("694"),
                      name: t("683"),
                      id: 3121,
                      value: "data-layer-query",
                      enable: false,
                    },
                    {
                      path: t("648"),
                      name: t("683"),
                      id: 3122,
                      value: "data-layer-import",
                      enable: false,
                    },
                    {
                      path: t("210"),
                      name: t("683"),
                      id: 3123,
                      value: "data-layer-delete",
                      enable: false,
                    },
                    {
                      path: t("652"),
                      name: t("683"),
                      id: 3124,
                      value: "data-layer-update",
                      enable: false,
                    },
                  ],
                },
              ],
            },
            {
              path: t("160"),
              name: t("680"),
              id: 32,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 321,
                  value: "data-route-query",
                  enable: false,
                },
                {
                  path: t("648"),
                  name: t("683"),
                  id: 322,
                  value: "data-route-import",
                  enable: false,
                },
                {
                  path: t("647"),
                  name: t("683"),
                  id: 323,
                  value: "data-route-export",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 324,
                  value: "data-route-update",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 325,
                  value: "data-route-delete",
                  enable: false,
                },
              ],
            },
            {
              path: t("159"),
              name: t("680"),
              id: 33,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              children: [
                {
                  path: t("159"),
                  name: t("680"),
                  id: 331,
                  enable: false,
                  itemVal: [],
                  itemChildren: [
                    {
                      path: t("694"),
                      name: t("683"),
                      id: 3311,
                      value: "task-task-query",
                      enable: false,
                    },
                    {
                      path: t("210"),
                      name: t("683"),
                      id: 3312,
                      value: "task-task-delete",
                      enable: false,
                    },
                    {
                      path: t("652"),
                      name: t("683"),
                      id: 3313,
                      value: "task-task-update",
                      enable: false,
                    },
                    {
                      path: t("647"),
                      name: t("683"),
                      id: 3314,
                      value: "task-task-export",
                      enable: false,
                    },
                  ],
                },
                {
                  path: t("700"),
                  name: t("680"),
                  id: 332,
                  enable: false,
                  indeterminate: false,
                  itemVal: [],
                  itemChildren: [
                    {
                      path: t("694"),
                      name: t("683"),
                      id: 3321,
                      value: "task-cycle-query",
                      enable: false,
                    },
                    {
                      path: t("209"),
                      name: t("683"),
                      id: 3322,
                      value: "task-cycle-add",
                      enable: false,
                    },
                    {
                      path: t("210"),
                      name: t("683"),
                      id: 3323,
                      value: "task-cycle-delete",
                      enable: false,
                    },
                    {
                      path: t("647"),
                      name: t("683"),
                      id: 3324,
                      value: "task-cycle-export",
                      enable: false,
                    },
                  ],
                },
              ],
            },
            {
              path: t("157"),
              name: t("680"),
              id: 34,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 511,
                  value: "data-version-query",
                  enable: false,
                },
                {
                  path: t("648"),
                  name: t("683"),
                  id: 341,
                  value: "data-version-import",
                  enable: false,
                },
                {
                  path: t("647"),
                  name: t("683"),
                  id: 342,
                  value: "data-version-export",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 343,
                  value: "data-version-delete",
                  enable: false,
                },
              ],
            },
          ],
        },
        {
          path: t("163"),
          name: t("680"),
          id: 4,
          look: false,
          enable: false,
          indeterminate: false,
          itemVal: [],
          children: [
            {
              path: t("701"),
              name: t("680"),
              id: 41,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 411,
                  value: "device-info-query",
                  enable: false,
                },
                {
                  path: t("702"),
                  name: t("683"),
                  id: 412,
                  value: "device-info-add",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 413,
                  value: "device-info-update",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 414,
                  value: "device-info-delete",
                  enable: false,
                },
              ],
            },
            {
              path: t("703"),
              name: t("680"),
              id: 42,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 421,
                  value: "device-model-query",
                  enable: false,
                },
                {
                  path: t("702"),
                  name: t("683"),
                  id: 422,
                  value: "device-model-add",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 423,
                  value: "device-model-update",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 424,
                  value: "device-model-delete",
                  enable: false,
                },
              ],
            },
          ],
        },
        {
          path: t("168"),
          name: t("680"),
          id: 5,
          look: false,
          enable: false,
          indeterminate: false,
          itemVal: [],
          children: [
            {
              path: t("168"),
              name: t("680"),
              id: 51,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 511,
                  value: "user-member-query",
                  enable: false,
                },
                {
                  path: t("209"),
                  name: t("683"),
                  id: 512,
                  value: "user-member-add",
                  enable: false,
                },
                {
                  path: t("647"),
                  name: t("683"),
                  id: 513,
                  value: "user-member-export",
                  enable: false,
                },
                {
                  path: t("648"),
                  name: t("683"),
                  id: 514,
                  value: "user-member-import",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 515,
                  value: "user-member-delete",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 516,
                  value: "user-member-update",
                  enable: false,
                },
                {
                  path: t("704"),
                  name: t("683"),
                  id: 517,
                  value: "user-member-list",
                  enable: false,
                },
                {
                  path: t("653"),
                  name: t("683"),
                  id: 518,
                  value: "user-password-reset",
                  enable: false,
                },
              ],
            },
            {
              path: t("705"),
              name: t("680"),
              id: 52,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 521,
                  value: "user-team-query",
                  enable: false,
                },
                {
                  path: t("702"),
                  name: t("683"),
                  id: 522,
                  value: "user-team-add",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 523,
                  value: "user-team-delete",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 524,
                  value: "user-team-update",
                  enable: false,
                },
              ],
            },
            {
              path: t("706"),
              name: t("680"),
              id: 53,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 531,
                  value: "user-role-query",
                  enable: false,
                },
                {
                  path: t("702"),
                  name: t("683"),
                  id: 532,
                  value: "user-role-add",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 533,
                  value: "user-role-delete",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 534,
                  value: "user-role-update",
                  enable: false,
                },
              ],
            },
            {
              path: t("707"),
              name: t("680"),
              id: 54,
              look: false,
              enable: false,
              indeterminate: false,
              itemVal: [],
              itemChildren: [
                {
                  path: t("694"),
                  name: t("683"),
                  id: 541,
                  value: "user-certificate-query",
                  enable: false,
                },
                {
                  path: t("702"),
                  name: t("683"),
                  id: 542,
                  value: "user-certificate-import",
                  enable: false,
                },
                {
                  path: t("210"),
                  name: t("683"),
                  id: 543,
                  value: "user-certificate-delete",
                  enable: false,
                },
                {
                  path: t("652"),
                  name: t("683"),
                  id: 544,
                  value: "user-certificate-update",
                  enable: false,
                },
                // {
                //   path: t("708"),
                //   name: t("683"),
                //   id: 545,
                //   value: "user-certificate-import",
                //   enable: false,
                // },
              ],
            },
          ],
        },
        // {
        //   path: t("173"),
        //   name: t("680"),
        //   id: 6,
        //   look: false,
        //   enable: false,
        //   indeterminate: false,
        //   itemVal: [],
        //   children: [
        //     {
        //       path: t("641"),
        //       name: t("680"),
        //       id: 61,
        //       value: "user-member-update",
        //       enable: false,
        //     },
        //     {
        //       path: t("709"),
        //       name: t("680"),
        //       id: 62,
        //       value: "user-certificate-import",
        //       enable: false,
        //     },
        //   ],
        // },
      ],
      selectAll: false, //是否全选
      selectId: [], //选中选项
      //   rolePermission: [], //权限字段
      isIndeterminate: false, //状态修改
      limits: [], //权限字段
    });
    onMounted(() => {
      methods.roleList();
      state.renderRoleMenu = state.roleMenu;
    });
    const methods = reactive({
      roleList: async () => {
        let res = await Roles.getRoleList();
        if (res.resultStatus) {
          state.roleLists = res.resultData;
          methods.getRoleInformation(res.resultData[0].id);
          methods.getPermissions(res.resultData[0].id);
          state.modifyFrom.roleId = res.resultData[0].id; //默认第一个
        }
      },

      jump: (id) => {
        state.navid = id;
      },

      choice: (i) => {
        state.lindex = i;
        if (i == 0) {
          // 清空
          state.modifyFrom.roleId = 0;
          // state.modifyFrom.rolePermissions='';
          methods.getRoleInformation(state.roleLists[0].id); //获取信息
          methods.getPermissions(state.roleLists[0].id); //获取权限字段
          state.modifyFrom.roleId = state.roleLists[0].id; //默认第一个
        } else {
          // 清空
          state.modifyFrom.roleId = 0;
          // state.modifyFrom.rolePermissions='';
          methods.getRoleInformation(state.roleLists[i].id);
          methods.getPermissions(state.roleLists[i].id);
          state.modifyFrom.roleId = state.roleLists[i].id; //默认第一个
        }
      },
      //角色信息
      getRoleInformation: async (id) => {
        if (id != undefined) {
          let res = await Roles.getRoleIdList(id);
          if (res.resultStatus) {
            state.roleInformation = res.resultData;
          }
        }
      },
      // 获取权限字段
      getPermissions: async (id) => {
        let res = await Roles.getPermissions(id);
        if (res.resultStatus) {
          let limits = [];
          limits = res.resultData.rolePermissions.split(";");
          state.limits = [];
          state.limits = limits;
          //有就勾选
          const data = deepCopyLimit(state.roleMenu);
          data.forEach((itemA) => {
            if (itemA.children != null) {
              const isChildAllCheckA = itemA.children.every((item) => {
                return item.enable;
              });
              const isChildren = itemA.children.some((item) => {
                return item.enable;
              });
              if (isChildren) {
                itemA.enable = isChildAllCheckA;
                itemA.indeterminate = !isChildAllCheckA;
              } else {
                itemA.enable = false;
                itemA.indeterminate = false;
              }

              itemA.children.forEach((itemB) => {
                if (itemB.children != null) {
                  itemB.children.forEach((itemC) => {
                    if (itemC.itemChildren != null) {
                      const isChildAllCheckD = itemC.itemChildren.every(
                        (item) => {
                          return item.enable;
                        }
                      );
                      const isChildrenD = itemC.itemChildren.some((item) => {
                        return item.enable;
                      });

                      if (isChildrenD) {
                        itemC.enable = isChildAllCheckD;
                        itemC.indeterminate = !isChildAllCheckD;
                      } else {
                        itemC.enable = false;
                        itemC.indeterminate = false;
                      }

                      const isChildAllCheckB = itemB.children.every((item) => {
                        return item.enable;
                      });
                      const isChildrenB = itemB.children.some((item) => {
                        return item.enable;
                      });
                      if (isChildrenB) {
                        itemB.enable = isChildAllCheckB;
                        itemB.indeterminate = !isChildAllCheckB;
                      } else {
                        itemB.enable = false;
                        itemB.indeterminate = false;
                      }

                      const isChildAllCheckA = itemA.children.every((item) => {
                        return item.enable;
                      });
                      const isChildrenA = itemA.children.some((item) => {
                        return item.enable;
                      });
                      if (isChildrenA) {
                        itemA.enable = isChildAllCheckA;
                        itemA.indeterminate = !isChildAllCheckA;
                      } else {
                        itemA.enable = false;
                        itemA.indeterminate = false;
                      }
                    }
                  });
                } else {
                  const isChildAllCheckB = itemB.itemChildren.every((item) => {
                    return item.enable;
                  });
                  const isChildrenB = itemB.itemChildren.some((item) => {
                    return item.enable;
                  });

                  // 当子元素都为空时，全部给false
                  if (isChildrenB) {
                    itemB.enable = isChildAllCheckB;
                    itemB.indeterminate = !isChildAllCheckB;
                  } else {
                    itemB.enable = false;
                    itemB.indeterminate = false;
                  }

                  const isChildAllCheckA = itemA.children.every((item) => {
                    return item.enable;
                  });
                  const isChildrenA = itemA.children.some((item) => {
                    return item.enable;
                  });
                  if (isChildrenA) {
                    itemA.enable = isChildAllCheckA;
                    itemA.indeterminate = !isChildAllCheckA;
                  } else {
                    itemA.enable = false;
                    itemA.indeterminate = false;
                  }
                }
              });
            }
          });
          state.renderRoleMenu = data;
          // 操作完重新判断是否全选
          state.selectAll = state.renderRoleMenu.every((item) => {
            return item.enable;
          });
          if (state.selectAll) {
            state.isIndeterminate = false;
          } else {
            state.isIndeterminate = methods.getCheckedData().length > 0;
          }
        }
      },
      // 添加
      addRole: () => {
        //  这个得拿到权限，如果是普通用户就不创建
        if (state.useType == "USER") {
          ElMessage.warning(t("710"));
        } else {
          state.addtitle = t("711");
          state.showVisType = "add";
          state.dialogVisible = true;
          state.addFrom.roleName = "";
          state.addFrom.type = "";
          state.gradeIndex = -1;
        }
      },
      // 修改
      modify: (item) => {
        // addFrom.name
        // 清空之前的选择，防止乱点亮
        state.gradeIndex = -1;
        state.addFrom.type = "";
        // 唤醒添加弹窗，把当前需要修改的传进区，
        state.addtitle = t("712");
        state.showVisType = "edit";
        state.dialogVisible = !state.dialogVisible;
        state.addFrom.roleName = item.roleName;
        state.addFrom.type = item.type;
        state.editType = item.type;
        state.addFrom.id = item.id;
        state.addFrom.rolePermissions = item.rolePermissions;
      },
      // 删除
      del: (i, id) => {
        state.delDialogShow = true;
        state.delId = id;
        state.delIndex = i;
        //打开删除弹窗
        // 二次确认删除
      },
      delCancel: () => {
        state.delDialogShow = false;
      },
      delSubmit: () => {
        Roles.delRoleIdList(state.delId).then((res) => {
          if (res.resultStatus) {
            state.delDialogShow = false;
            state.roleLists.splice(state.delIndex, 1);
            methods.roleList();
            ElMessage.success(t("560"));
          }
        });
      },
      // 选择等级
      changeGrade: (i) => {
        // 全局添加权限 这个得加条件如果当前是管理员则不能创建管理员，只能创建普通用户
        // 登录后拿到用户信息才能限制
        if (state.useType == "ADMIN") {
          if (state.changeGarde[i] == t("670")) {
            ElMessage.warning(t("710"));
          } else {
            state.gradeIndex = i;
            state.addFrom.type =
              state.changeGarde[i] == t("670") ? "ADMIN" : "USER";
          }
        } else {
          state.gradeIndex = i;
          state.addFrom.type =
            state.changeGarde[i] == t("670") ? "ADMIN" : "USER";
        }
      },
      // 取消
      cancel: () => {
        state.dialogVisible = false;
      },
      // 确定
      subMit: () => {
        roleRef.value.validate((valid) => {
          if (valid) {
            if (state.showVisType == "add") {
              methods.addSubmit();
            } else {
              methods.editSubmit();
            }
          } else {
            return false;
          }
        });
      },
      // 新增提交
      addSubmit: () => {
        if (state.addFrom.type == "ADMIN" && state.useType == "ADMIN") {
          ElMessage.warning(t("710"));
        } else {
          Roles.addRole(state.addFrom).then((res) => {
            if (res.resultStatus) {
              state.dialogVisible = false;
              ElMessage.success(t("713"));
              methods.roleList();
            }
          });
        }
      },
      // 编辑提交
      editSubmit: () => {
        if (state.useType == "ADMIN" && state.editType == "ADMIN") {
          ElMessage.warning(t("710"));
        } else if (state.useType == "USER" && state.editType == "ADMIN") {
          ElMessage.warning(t("710"));
        } else {
          Roles.modifyRole(state.addFrom).then((res) => {
            if (res.resultStatus) {
              state.dialogVisible = false;
              ElMessage.success(t("714"));
              methods.roleList();
            }
          });
        }
      },

      // 对应列表单选择
      handleCheckedItemChange() {
        const list = deepCopy(state.renderRoleMenu);
        list.forEach((itemA) => {
          if (itemA.children != null) {
            const isChildAllCheckA = itemA.children.every((item) => {
              return item.enable;
            });
            const isChildren = itemA.children.some((item) => {
              return item.enable;
            });
            if (isChildren) {
              itemA.enable = isChildAllCheckA;
              itemA.indeterminate = !isChildAllCheckA;
            } else {
              itemA.enable = false;
              itemA.indeterminate = false;
            }

            itemA.children.forEach((itemB) => {
              if (itemB.children != null) {
                itemB.children.forEach((itemC) => {
                  if (itemC.itemChildren != null) {
                    const isChildAllCheckD = itemC.itemChildren.every(
                      (item) => {
                        return item.enable;
                      }
                    );
                    const isChildrenD = itemC.itemChildren.some((item) => {
                      return item.enable;
                    });

                    if (isChildrenD) {
                      itemC.enable = isChildAllCheckD;
                      itemC.indeterminate = !isChildAllCheckD;
                    } else {
                      itemC.enable = false;
                      itemC.indeterminate = false;
                    }

                    const isChildAllCheckB = itemB.children.every((item) => {
                      return item.enable;
                    });
                    const isChildrenB = itemB.children.some((item) => {
                      return item.enable;
                    });
                    if (isChildrenB) {
                      itemB.enable = isChildAllCheckB;
                      itemB.indeterminate = !isChildAllCheckB;
                    } else {
                      itemB.enable = false;
                      itemB.indeterminate = false;
                    }

                    const isChildAllCheckA = itemA.children.every((item) => {
                      return item.enable;
                    });
                    const isChildrenA = itemA.children.some((item) => {
                      return item.enable;
                    });
                    if (isChildrenA) {
                      itemA.enable = isChildAllCheckA;
                      itemA.indeterminate = !isChildAllCheckA;
                    } else {
                      itemA.enable = false;
                      itemA.indeterminate = false;
                    }
                  }
                });
              } else {
                const isChildAllCheckB = itemB.itemChildren.every((item) => {
                  return item.enable;
                });
                const isChildrenB = itemB.itemChildren.some((item) => {
                  return item.enable;
                });

                // 当子元素都为空时，全部给false
                if (isChildrenB) {
                  itemB.enable = isChildAllCheckB;
                  itemB.indeterminate = !isChildAllCheckB;
                } else {
                  itemB.enable = false;
                  itemB.indeterminate = false;
                }

                const isChildAllCheckA = itemA.children.every((item) => {
                  return item.enable;
                });
                const isChildrenA = itemA.children.some((item) => {
                  return item.enable;
                });
                if (isChildrenA) {
                  itemA.enable = isChildAllCheckA;
                  itemA.indeterminate = !isChildAllCheckA;
                } else {
                  itemA.enable = false;
                  itemA.indeterminate = false;
                }
              }
            });
          }
        });
        //重新赋值
        state.renderRoleMenu = list;
        state.selectAll = state.renderRoleMenu.every((item) => {
          return item.enable;
        });
        if (state.selectAll) {
          state.isIndeterminate = false;
        } else {
          state.isIndeterminate = methods.getCheckedData().length > 0;
        }
      },
      handleCheckAllChange(val) {
        state.selectAll = val;
        state.isIndeterminate = false;
        const list = deepCopy(state.renderRoleMenu);
        list.forEach((itemA) => {
          // 最深4层
          itemA.enable = val;
          itemA.indeterminate = false;
          itemA.children.forEach((itemB) => {
            if (itemB.children != null) {
              itemB.enable = val;
              itemB.indeterminate = false;
              itemB.children.forEach((itemC) => {
                if (itemC.itemChildren != null) {
                  itemC.itemChildren.map((item) => {
                    item.enable = val;
                  });
                  itemC.enable = val;
                  itemC.indeterminate = false;
                }
              });
            } else {
              // 没有就赋值
              if (itemB.itemChildren != null) {
                itemB.enable = val;
                itemB.indeterminate = false;
                itemB.itemChildren.map((item) => {
                  item.enable = val;
                });
              }
            }
          });
        });
        state.renderRoleMenu = list;
      },

      // 权限选择
      handleCheckChange(val, checked) {
        const fId = findP(val.id, state.renderRoleMenu); //找到父级

        let len = fId.length - 1;
        const list = deepCopy(state.renderRoleMenu);
        list.forEach((item) => {
          // 第一层单选
          // 第二层
          if (item.children != null) {
            item.children.forEach((itemA) => {
              if (itemA.children != null) {
                itemA.children.forEach((itemB) => {
                  if (itemB.id === fId[len]) {
                    itemB.itemChildren.map((item) => {
                      item.enable = checked;
                    });

                    const isChildAllCheckB = itemB.itemChildren.every(
                      (item) => {
                        return item.enable;
                      }
                    );
                    const isChildrenB = itemB.itemChildren.some((item) => {
                      return item.enable;
                    });

                    if (isChildrenB) {
                      itemB.enable = isChildAllCheckB;
                      itemB.indeterminate = !isChildAllCheckB;
                    } else {
                      itemB.enable = false;
                      itemB.indeterminate = false;
                    }

                    const isChildAllCheckA = itemA.children.every((item) => {
                      return item.enable;
                    });
                    const isChildrenA = itemA.children.some((item) => {
                      return item.enable;
                    });
                    if (isChildrenA) {
                      itemA.enable = isChildAllCheckA;
                      itemA.indeterminate = !isChildAllCheckA;
                    } else {
                      itemA.enable = false;
                      itemA.indeterminate = false;
                    }

                    const isChildAllCheck = item.children.every((item) => {
                      return item.enable;
                    });
                    const isChildren = item.children.some((item) => {
                      return item.enable;
                    });
                    if (isChildren) {
                      item.enable = isChildAllCheck;
                      item.indeterminate = !isChildAllCheck;
                    } else {
                      item.enable = false;
                      item.indeterminate = false;
                    }
                  } else {
                    return;
                  }
                });
              } else {
                if (itemA.id === fId[len]) {
                  itemA.itemChildren.map((item) => {
                    item.enable = checked;
                  });
                  const isChildAllCheckA = itemA.itemChildren.every((item) => {
                    return item.enable;
                  });
                  const isChildrenA = itemA.itemChildren.some((item) => {
                    return item.enable;
                  });
                  if (isChildrenA) {
                    itemA.enable = isChildAllCheckA;
                    itemA.indeterminate = !isChildAllCheckA;
                  } else {
                    itemA.enable = false;
                    itemA.indeterminate = false;
                  }

                  const isChildAllCheck = item.children.every((item) => {
                    return item.enable;
                  });
                  const isChildren = item.children.some((item) => {
                    return item.enable;
                  });
                  if (isChildren) {
                    item.enable = isChildAllCheck;
                    item.indeterminate = !isChildAllCheck;
                  } else {
                    item.enable = false;
                    item.indeterminate = false;
                  }
                } else {
                  return;
                }
              }
            });

            const isChildAllCheckA = item.children.every((item) => {
              return item.enable;
            });
            const isChildren = item.children.some((item) => {
              return item.enable;
            });
            if (isChildren) {
              item.enable = isChildAllCheckA;
              item.indeterminate = !isChildAllCheckA;
            } else {
              item.enable = false;
              item.indeterminate = false;
            }
          }
        });
        //重新赋值
        state.renderRoleMenu = list;
        // }
        // 操作完重新判断是否全选
        state.selectAll = state.renderRoleMenu.every((item) => {
          return item.enable;
        });
        if (state.selectAll) {
          state.isIndeterminate = false;
        } else {
          state.isIndeterminate = methods.getCheckedData().length > 0;
        }
      },

      /**
       * @Description: 获取选择的数据
       * @param {*}
       * @return {*}
       */
      getCheckedData: () => {
        const arr = [];
        // 遍历拿到为ture的数据
        state.renderRoleMenu.forEach((itemA) => {
          itemA.children.map((itemB) => {
            if (itemB.children != null) {
              if (itemB.enable) {
                arr.push(itemB);
              }
              itemB.children.forEach((itemC) => {
                if (itemC.itemChildren != null) {
                  if (itemC.enable) {
                    arr.push(itemC);
                  }
                } else {
                  if (itemC.enable) {
                    arr.push(itemC);
                  }
                }
              });
            } else {
              if (itemB.enable) {
                arr.push(itemB);
              }
            }
          });
        });
        return arr;
      },

      // 找到当前id
      findItemById: (list, id) => {
        // 每次进来使用find遍历一次
        let res = list.find((item) => item.id == id);
        if (res) {
          return res;
        } else {
          for (let i = 0; i < list.length; i++) {
            if (
              list[i].children instanceof Array &&
              list[i].children.length > 0
            ) {
              res = methods.findItemById(list[i].children, id);
              if (res) return res;
            }
          }
          return null;
        }
      },
      // 当前行
      clickTable: (row) => {
        multipleTableRef.value.toggleRowExpansion(row);
      },
      // 编辑权限
      roleEdit: () => {
        state.editShow = !state.editShow;
      },
      // 关闭权限编辑
      roleEditClose: () => {
        state.editShow = true;
        methods.getPermissions(state.modifyFrom.roleId);
      },
      // 保存权限编辑
      roleEditSubmit: () => {
        // 提交完成后
        const arr = roleValue(state.renderRoleMenu);
        state.modifyFrom.rolePermissions = arr.join(";");
        Roles.modifyPermissions(state.modifyFrom).then((res) => {
          if (res.resultStatus) {
            ElMessage.success(t("714"));
            // 这里有点问题得判断修改是否是管理员修改普通用户权限就不用存本地
            // localStorage.setItem(
            //   "permissions",
            //   state.modifyFrom.rolePermissions
            // );
            state.editShow = true;
            methods.getPermissions(state.modifyFrom.roleId);
            state.modifyFrom.rolePermissions = "";
          }
        });
      },
      //提示编辑
      editDialog: () => {
        ElMessage.warning(t("715"));
      },
    });
    // 递归
    function deepCopy(source) {
      // if (typeof(source) !== Object && source !== null) return source //如果不是对象的话直接返回
      const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
      for (const k in source) {
        if (typeof source[k] === "object") {
          target[k] = deepCopy(source[k]);
        } else {
          target[k] = source[k];
        }
      }
      return target;
    }
    // 回显匹配
    function deepCopyLimit(source) {
      const target = []; //Array.isArray(source) ? [] : {}; //数组兼容
      for (const k in source) {
        if (typeof source[k] === "object") {
          target[k] = deepCopyLimit(source[k]);
        } else {
          target[k] = source[k];
        }
      }
      if (target.value != undefined || target.value != null) {
        if (state.limits.length != 0) {
          state.limits.map((item) => {
            if (target.value === item) {
              target.enable = true;
            }
          });
        }
      }
      return target;
    }

    // 找到当前id
    function findItemById(list, id) {
      // 每次进来使用find遍历一次
      let res = list.find((item) => item.id == id);
      if (res) {
        return res;
      } else {
        for (let i = 0; i < list.length; i++) {
          if (
            list[i].children instanceof Array &&
            list[i].children.length > 0
          ) {
            res = findItemById(list[i].children, id);
            if (res) return res;
          }
        }
        return null;
      }
    }

    // 查找父节点
    function findP(id, list = [], result = []) {
      for (let i = 0; i < list.length; i += 1) {
        const item = list[i];
        // 找到目标
        if (item.id === id) {
          // 加入到结果中
          result.push(item.id);
          // 因为可能在第一层就找到了结果，直接返回当前结果
          if (result.length === 1) return result;
          return true;
        }
        // 如果存在下级节点，则继续遍历
        if (item.children) {
          // 预设本次是需要的节点并加入到最终结果result中
          result.push(item.id);
          const find = findP(id, item.children, result);
          // 如果不是false则表示找到了，直接return，结束递归
          if (find) {
            return result;
          }
          // 到这里，意味着本次并不是需要的节点，则在result中移除
          result.pop();
        }
      }
      // 如果都走到这儿了，也就是本轮遍历children没找到，将此次标记为false
      return false;
    }
    //遍历tree取出value，
    function roleValue(data = [], arr = []) {
      for (let item of data) {
        if (item.enable == true && item.value != undefined) {
          arr.push(item.value);
        }
        if(item.children && item.children.length){
          roleValue(item.children, arr);
        }
        if (item.itemChildren && item.itemChildren.length){
          roleValue(item.itemChildren, arr);
        }
          
      }
      return arr;
    }

    return {
      ...toRefs(state),
      ...toRefs(methods),
      multipleTableRef,
      roleRef,
      deepCopy,
      findP,
      deepCopyLimit,
      findItemById,
      roleValue,
    };
  },
});
</script>

<style lang="less">
.atooltip {
  width: 15rem;
  border: none !important;
  background: white !important;
  color: black !important;
  .feildG {
    padding-left: 0.875rem;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    font-size: 1rem;
  }
  .feildF {
    padding: 0.3125rem 0.875rem 0.3125rem 0.875rem;
    font-family: Source Han Sans CN;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.roleToolTip {
  background: #004375 !important;
  border: 1px solid #004375 !important;
}
.el-popper.is-customized {
  padding: 6px 12px;
  background: #004375;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: #004375;
  right: 0;
}
</style>

<style lang="less" scoped>
.popover_ul {
  z-index: 2555;
  color: #fff;
  border-radius: 2px;
  background-color: @lightBackground;
  // background: rgba(0, 28, 49, 0.8);
  border: 0px solid #51d2ff;
  li {
    width: 9.375rem;
    text-align: center;
    padding: 0.875rem 0;
    cursor: pointer;
  }
  li:hover {
    background: #0090da;
  }
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  /deep/.el-dialog {
    background: #004375 !important;
  }
  /deep/.el-dialog__body {
    border-top: 1px solid #037f91;
    padding: 0.8125rem 1.25rem 1.25rem;
  }
  /deep/.el-dialog__title {
    color: #ffffff;
  }

  /deep/.el-input {
    box-sizing: border-box;
  }
  /deep/.el-input__wrapper {
    border-radius: 0;
    background-color: #0d4460;
    box-shadow: 0 0 0 0px;
  }
  /deep/ .el-input__inner {
    color: #ffffff;
    height: 2.5rem !important;
  }
  /deep/.el-dialog__close {
    color: #ffffff !important;
  }

  /deep/.el-form-item {
    margin-bottom: 2rem;
  }
  /deep/.el-form-item .el-form-item__label {
    margin-bottom: 0.25rem !important;
    color: #ffffff;
    display: block;
  }
  /deep/.el-form--default.el-form--label-top
    .el-form-item
    .el-form-item__label {
    margin-bottom: 0;
  }
  /deep/.el-form-item__content {
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.nav {
  width: 22.5rem;
  height: 6.625rem;
}
.members {
  position: absolute;
  top: 7.125rem;
  left: 0;
  bottom: 0;
  width: 22.5rem;
  background: rgba(0, 28, 49, 0.8);
  height: auto;
  .header {
    width: 100%;
    height: 3.875rem;
    .headerN {
      color: #36e5ff;
      padding-left: 0.8125rem;
      font-size: 1.25rem;
      .header_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
          font-size: 1.25rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #36e5ff;
        }
        .add_btn {
          width: 1.5rem;
          height: 1.5rem;
          line-height: 1.1;
          background: #33a2e7;
          color: #fff;
          font-size: 1.375rem;
          font-weight: bold;
          text-align: center;
          border-radius: 50%;
        }
      }
    }
    .add {
      width: 1.4375rem;
      height: 1.4375rem;
      margin-right: 0.875rem;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .List {
    height: calc(100% - 3.875rem);
    overflow: hidden;
    overflow: auto;
    .libox {
      .li {
        position: relative;
        width: 100%;
        height: 2.5rem;
        cursor: pointer;
        .licon {
          width: 1.125rem;
          height: 1.125rem;
          color: #ffffff;
          margin-left: 3.1875rem;
          margin-right: 1.0625rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          width: calc(100% - 8.5rem);
          height: 100%;
          line-height: 2.5rem;
          color: #ffffff;
        }
        .ricon {
          position: absolute;
          top: 0.6875rem;
          right: 2rem;
          width: 0.0875rem;
          height: 1.125rem;
          color: white;
        }
      }
      div:hover {
        background: #0090da;
      }
      .active {
        background: #0090da;
      }
    }
  }
}
.conRight {
  position: absolute;
  top: 0;
  left: 23.4375rem;
  bottom: 0;
  right: 0;
  background: rgba(0, 28, 49, 0.8);
  height: auto;
}
// .dialog_bg {
//   z-index: 2555;
//   background: #004375;
// }
// 弹窗
.roleName {
  color: #ffffff;
  font-size: 0.875rem;
  margin-bottom: 0.6875rem;
  z-index: 2555;
}
.rolePut {
  width: 21.5625rem;
  height: 2.5rem;
  box-sizing: border-box;
  border: 1px solid #25a7d5;

  color: #ffffff;
  outline: 0;
}
input::-webkit-input-placeholder {
  color: #a9b8c3;
}
.roleType {
  width: 100%;
  position: relative;
  .roleGarde {
    position: relative;
    padding-left: 9px;
    span {
      color: #ffffff;
    }
    .feild {
      position: relative;
      margin-left: 0.5rem;
      width: 0.875rem;
      height: 0.875rem;
    }
    img {
      position: relative;
      top: .0625rem;
      margin-left: 0.5rem;
      width: 0.875rem;
      height: 0.875rem;
    }
  }
  .roleGarde::before{
    content: '*';
    color: #f56c6c;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

.del {
  /deep/.el-dialog__headerbtn .el-dialog__close {
    color: #36e5ff !important;
  }
}
.changeG {
  width: 100%;
  .gbtn {
    width: 9.625rem;
    height: 2.5rem;
    line-height: 2.5rem;
    text-align: center;
    background: #0d4460;
    border: 1px solid #25a7d5;
    color: #ffffff;
  }
  .active {
    color: #ffffff;
    background: #44bcfa;
  }
}
.footBtn {
  margin-top: 3.6875rem;
}
.lBtn {
  width: 6.25rem;
  height: 2.5rem;
  color: #ffffff;
  line-height: 2.5rem;
  text-align: center;
  background: #001c31;
  border: 1px solid #00f5ff;
}
.rBtn {
  margin-left: 1.625rem;
  width: 6.25rem;
  height: 2.5rem;
  color: #ffffff;
  line-height: 2.5rem;
  text-align: center;
  background: #00959b;
  border: 1px solid #00f5ff;
}
</style>
<style lang='less' scoped>
.role {
  width: 100%;
  height: 100%;
  /deep/ .el-table {
    color: #36e5ff;
    background: none !important;
  }
  // 去除表头边框
  /deep/.el-table .el-table__header-wrapper .el-table__header th.is-leaf {
    color: #36e5ff;
    border-right: none !important;
    border-bottom: 1px solid #004375 !important;
  }
  // 设置表头背景颜色
  /deep/ .el-table th.el-table__cell {
    background: rgba(2, 58, 89, 0.7) !important;
  }
  // 边框样式
  /deep/.el-table,
  .el-table th.is-leaf {
    border-left: 1px solid #004375;
  }
  /deep/.el-table td:last-child {
    border-right: 1px solid #004375;
  }
  /deep/.el-table td {
    border-bottom: 1px solid #004375 !important;
  }

  // tbody背景颜色
  /deep/.el-table tr {
    background: rgba(0, 28, 49, 0.6) !important;
  }

  // 移入
  /deep/ .el-table__body tr:hover > td {
    background: rgba(0, 28, 49, 0.8) !important;
  }
  // 去除底部滑动白边
  /deep/.el-table__inner-wrapper::before {
    height: 0;
  }
  /deep/.el-table__inner-wrapper {
    height: 95%;
    // background: rgba(0, 28, 49, 0.8);
  }
  // 头部高度
  /deep/.el-table__header-wrapper .el-table__header {
    height: 3rem;
  }
  // 表体高度
  /deep/.el-table__row {
    height: 4.5rem;
  }
  //将滑动条移动底部
  /deep/.el-table__body-wrapper {
    height: calc(100% - 48px);
  }
  /deep/.el-scrollbar__view {
    height: 100%;
  }
  //选择器
  /deep/.el-checkbox__inner {
    border: 1px solid #55d0fa;
    background-color: #001c31;
  }
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #0090da;
    border-color: none !important;
  }
  /deep/.el-checkbox {
    --el-checkbox-checked-text-color: #fff;
  }
  /deep/ .el-table .cell {
    display: flex;
    align-items: center;
  }
  /deep/.el-table__indent{
    padding-left: 1rem !important;
  }
  /deep/ .el-row {
    width: 100%;
    align-items: center;
  }
}
.rolebox {
  height: calc(100% - 12.125rem);
  width: 100%;
  overflow: auto;
  .role_content {
    position: relative;
  }
  .role_model {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    background-color: rgba(0, 28, 49, 0.4);
    overflow: hidden;
    overflow-y: scroll;
  }
}
.rolehd {
  position: relative;
  box-sizing: border-box;
  padding: 1.1875rem 2.3125rem 0 2.3125rem;
  width: 100%;
  height: 7.125rem;
  display: flex;
  flex-direction: column;
  color: #36e5ff;
  background: rgba(0, 28, 49, 0.7);
  .role_edit {
    position: absolute;
    right: 1.5625rem;
    bottom: 0.3125rem;
    color: #36e5ff;
    cursor: pointer;
  }
}
.roletop {
  height: 2.25rem;
  line-height: 2.25rem;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #30d1ea;
}
.role_btn {
  height: 2.5rem;
  margin-top: 1.3125rem;
}
.rolet {
  color: #30d1ea;
  span {
    color: #ffffff;
    margin-left: 0.5625rem;
  }
  .namer {
    margin-left: 11.5625rem;
  }
}
</style>