<template>
  <!-- 选择栏 -->
  <div class="nav">
     <div
    class="navlist"
    :class="{ activte: $route.matched[1].path === item.path }"
    v-for="item in navlist"
    :key="item.id"
    @click="jump(item.path)"
  >
    <img
      v-if="$route.matched[1].path === item.path"
      src="../../asset/img/person_1.png"
      alt
    />
    <img v-else src="../../asset/img/person_2.png" alt />
    <span>{{ item.name }}</span>
  </div>
  </div>
 
</template>

<script>
import { t } from '../../languages';
import { router } from "@/router";

export default {
  props: {
    navlist: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    /* 跳转 */
    function jump(path) {
      router.push(path);
    }

    return {
      jump,
    };
  },
};
</script>

<style scoped lang="less">
.nav{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .navlist {
  position: relative;
  width: 11rem;
  height: 3.0625rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    top: 0.625rem;
    left: 1.25rem;
    color: #cccccc;
  }
}
}

.activte {
  font-size: 1.125rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-shadow: 0px 2px 6px #091c3b;
  background: linear-gradient(172deg, #afe0fc 0.68359375%, #ffffff 98.6328125%);
  -webkit-background-clip: text;
}
.navlist:last-child {
  margin-bottom: 0;
}
</style>